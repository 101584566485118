import React from 'react'

/**
* @author
* @function Dashboard
**/

export const Dashboard = (props) => {
    return (
        <div>Wellcome to Performance Management</div>
    )
}
