// login
export const USER_LOGIN = "USER_LOGIN"
export const USER_LOGIN_SUCCEEDED = "USER_LOGIN_SUCCEEDED"
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED"

// projectss
export const FETCH_PROJECTS = "FETCH_PROJECTS"
export const FETCH_PROJECTS_SUCCEEDED = "FETCH_PROJECTS_SUCCEEDED"
export const FETCH_PROJECTS_FAILED = "FETCH_PROJECTS_FAILED"
// kpi
export const FETCH_KPI = "FETCH_KPI"
export const FETCH_KPI_SUCCEEDED = "FETCH_KPI_SUCCEEDED"
export const FETCH_KPI_FAILED = "FETCH_KPI_FAILED"

// fetch pfms

export const FETCH_PFMS_1 = "FETCH_PFMS_1"
export const FETCH_PFMS_1_SUCCEEDED = "FETCH_PFMS_1_SUCCEEDED"
export const FETCH_PFMS_1_FAILED = "FETCH_PFMS_1_FAILED"

export const FETCH_PFMS_2 = "FETCH_PFMS_2"
export const FETCH_PFMS_2_SUCCEEDED = "FETCH_PFMS_2_SUCCEEDED"
export const FETCH_PFMS_2_FAILED = "FETCH_PFMS_2_FAILED"

export const FETCH_PFMS_3 = "FETCH_PFMS_3"
export const FETCH_PFMS_3_SUCCEEDED = "FETCH_PFMS_3_SUCCEEDED"
export const FETCH_PFMS_3_FAILED = "FETCH_PFMS_3_FAILED"

export const FETCH_PFMS_4 = "FETCH_PFMS_4"
export const FETCH_PFMS_4_SUCCEEDED = "FETCH_PFMS_4_SUCCEEDED"
export const FETCH_PFMS_4_FAILED = "FETCH_PFMS_4_FAILED"

// fetch username

export const FETCH_USER = "FETCH_USER"
export const FETCH_USER_SUCCEEDED = "FETCH_USER_SUCCEEDED"
export const FETCH_USER_FAILED = "FETCH_USER_FAILED"

// update password

export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const UPDATE_PASSWORD_SUCCEEDED = "UPDATE_PASSWORD_SUCCEEDED"
export const UPDATE_PASSWORD_FAILED = "UPDATE_PASSWORD_FAILED"


// fetch password

export const FETCH_PASSWORD = "FETCH_PASSWORD"
export const FETCH_PASSWORD_SUCCEEDED = "FETCH_PASSWORD_SUCCEEDED"
export const FETCH_PASSWORD_FAILED = "FETCH_PASSWORD_FAILED"

// add pfm
export const ADD_PFM_1 = "ADD_PFM_1"
export const ADD_PFM_1_SUCCEEDED = "ADD_PFM_1_SUCCEEDED"
export const ADD_PFM_1_FAILED = "ADD_PFM_1_FAILED"

export const ADD_PFM_2 = "ADD_PFM_2"
export const ADD_PFM_2_SUCCEEDED = "ADD_PFM_2_SUCCEEDED"
export const ADD_PFM_2_FAILED = "ADD_PFM_2_FAILED"

export const ADD_PFM_3 = "ADD_PFM_3"
export const ADD_PFM_3_SUCCEEDED = "ADD_PFM_3_SUCCEEDED"
export const ADD_PFM_3_FAILED = "ADD_PFM_3_FAILED"

export const ADD_PFM_4 = "ADD_PFM_4"
export const ADD_PFM_4_SUCCEEDED = "ADD_PFM_4_SUCCEEDED"
export const ADD_PFM_4_FAILED = "ADD_PFM_4_FAILED"


// delete pfm

export const DELETE_PFM_1 = "DELETE_PFM_1"
export const DELETE_PFM_1_SUCCEEDED = "DELETE_PFM_1_SUCCEEDED"
export const DELETE_PFM_1_FAILED = "DELETE_PFM_1_FAILED"

export const DELETE_PFM_2 = "DELETE_PFM_2"
export const DELETE_PFM_2_SUCCEEDED = "DELETE_PFM_2_SUCCEEDED"
export const DELETE_PFM_2_FAILED = "DELETE_PFM_2_FAILED"

export const DELETE_PFM_3 = "DELETE_PFM_3"
export const DELETE_PFM_3_SUCCEEDED = "DELETE_PFM_3_SUCCEEDED"
export const DELETE_PFM_3_FAILED = "DELETE_PFM_3_FAILED"

export const DELETE_PFM_4 = "DELETE_PFM_4"
export const DELETE_PFM_4_SUCCEEDED = "DELETE_PFM_4_SUCCEEDED"
export const DELETE_PFM_4_FAILED = "DELETE_PFM_4_FAILED"

// edit pfm 

export const EDIT_PFM_1 = "EDIT_PFM_1"
export const EDIT_PFM_1_SUCCEEDED = "EDIT_PFM_1_SUCCEEDED"
export const EDIT_PFM_1_FAILED = "EDIT_PFM_1_FAILED"

export const EDIT_PFM_2 = "EDIT_PFM_2"
export const EDIT_PFM_2_SUCCEEDED = "EDIT_PFM_2_SUCCEEDED"
export const EDIT_PFM_2_FAILED = "EDIT_PFM_2_FAILED"

export const EDIT_PFM_3 = "EDIT_PFM_3"
export const EDIT_PFM_3_SUCCEEDED = "EDIT_PFM_3_SUCCEEDED"
export const EDIT_PFM_3_FAILED = "EDIT_PFM_3_FAILED"

export const EDIT_PFM_4 = "EDIT_PFM_4"
export const EDIT_PFM_4_SUCCEEDED = "EDIT_PFM_4_SUCCEEDED"
export const EDIT_PFM_4_FAILED = "EDIT_PFM_4_FAILED"

// fetch pfm
export const FETCH_PFM_1= "FETCH_PFM_1"
export const FETCH_PFM_1_SUCCEEDED = "FETCH_PFM_1_SUCCEEDED"
export const FETCH_PFM_1_FAILED = "FETCH_PFM_1_FAILED"

export const FETCH_PFM_2= "FETCH_PFM_2"
export const FETCH_PFM_2_SUCCEEDED = "FETCH_PFM_2_SUCCEEDED"
export const FETCH_PFM_2_FAILED = "FETCH_PFM_2_FAILED"

export const FETCH_PFM_3= "FETCH_PFM_3"
export const FETCH_PFM_3_SUCCEEDED = "FETCH_PFM_3_SUCCEEDED"
export const FETCH_PFM_3_FAILED = "FETCH_PFM_3_FAILED"

export const FETCH_PFM_4= "FETCH_PFM_4"
export const FETCH_PFM_4_SUCCEEDED = "FETCH_PFM_4_SUCCEEDED"
export const FETCH_PFM_4_FAILED = "FETCH_PFM_4_FAILED"

// leave

export const ADD_LEAVE= "ADD_LEAVE"
export const ADD_LEAVE_SUCCEEDED = "ADD_LEAVE_SUCCEEDED"
export const ADD_LEAVE_FAILED = "ADD_LEAVE_FAILED"

export const FETCH_LEAVE = "FETCH_LEAVE"
export const FETCH_LEAVE_SUCCEEDED = "FETCH_LEAVE_SUCCEEDED"
export const FETCH_LEAVE_FAILED = "FETCH_LEAVE_FAILED"

export const DELETE_LEAVE = "DELETE_LEAVE"
export const DELETE_LEAVE_SUCCEEDED = "DELETE_LEAVE_SUCCEEDED"
export const DELETE_LEAVE_FAILED = "DELETE_LEAVE_FAILED"